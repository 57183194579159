.outer-body{
    background: rgb(40, 26, 37);
    width: 95%;
    min-height: 100vh;
    max-height: 100%;
    margin: auto;
    margin-top: 6vh;
    padding: 4vw;
    margin-bottom: 5vh;
}

.outer-body .inner-body {
    background: linear-gradient(rgba(198, 190, 190, 0.5), rgba(145, 118, 61, 0.845)), 
                url('../img/5217c521542d3c754a36fc825f23de6c.jpg') no-repeat center center;
    background-size: cover;
    font-weight: bolder;
    min-height: 85vh;
    max-height: 100%;
    text-align: justify;
    color: #030303; 
    padding: 2vw;
}

.outer-body .inner-body p, 
.outer-body .inner-body h1, 
.outer-body .inner-body h2 {
    color: #000000; 
}

.accordion-flush .accordion-item .accordion-button, 
.accordion-flush .accordion-item .accordion-button.collapsed {
    
    margin-top: 5vh;
    padding: 5vh  0 5vh 5vh;
    background: linear-gradient(rgba(198, 190, 190, 0.5), rgba(145, 118, 61, 0.845)), 
                url('../img/5217c521542d3c754a36fc825f23de6c.jpg') no-repeat center center;
                background-size: cover;
    font-weight: bolder;
}

.accordion-button::after {
    content: url('../img/plus-circle-svgrepo-com.svg'); 
    margin-right: 1cm;
    transition: transform 0.3s ease-in-out; 
}



.accordion-body {
    background: linear-gradient(rgba(198, 190, 190, 0.5), rgba(132, 102, 35, 0.845)), 
                url('../img/Old-Vintage-Paper-Textured-background-Graphics-69135662-1.jpg') no-repeat center center;
                background-size: cover;
    text-align: justify;
    color: #000000; 
}

.accordion-flush .accordion-item {
    background: linear-gradient(rgba(198, 190, 190, 0.5), rgba(145, 118, 61, 0.845)), 
                url('../img/5217c521542d3c754a36fc825f23de6c.jpg') no-repeat center center;
    border: none !important;
}

.contact-inner-body {
    max-height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    background: rgba(54, 24, 68, 1) !important;
    padding: 0 !important;
}

.contact-inner-body .contact {
    margin: 2vw;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-inner-body .contact a {
    text-decoration: none;
    color: #222; /* Darker color for contact links */
    margin-top: 1vh;
    margin-bottom: -1.5vh;
}

@media screen and (max-width: 970px) {
    .contact-inner-body {
        height: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(54, 24, 68, 1) !important;
        padding: 0 !important;
    }
    
    .outer-body .contact-outer-body {
        height: 100% !important;
    }
    
    .contact-inner-body .contact {
        margin: 2vw;
        margin-top: 6vw;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3vw;
    }

    .contact-inner-body .contact a {
        text-decoration: none;
        color: #222; /* Darker color for contact links in media query */
        margin-top: 1vh;
        margin-bottom: -1.5vh;
    }
}

@media screen and (max-width: 456px) {
    .contact-inner-body .contact a {
        font-size: 4vw;
    }
}
