
    .workshopHeading{
        /* background: linear-gradient(45deg, rgb(176, 96, 43), rgba(198, 133, 68, 0.436));  */
        border-top: 5px solid rgba(255, 235, 205, 0.2); 
        position: relative;
        height: 13vh;
        width: 80%;
        margin: auto;
        margin-top: 13vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4rem;
        font-weight: 700;
        border-radius: 30px; 
        color: #8B3A3A; 
        
    }
    .workshopTitle{
        background: linear-gradient(45deg, #8B3A3A, rgb(139, 69, 19), #8B3A3A, rgb(139, 69, 19), #ac4747, rgb(174, 112, 67));    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'BasheqVintage', serif;
    }

.cards{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 50px;
    padding: 10vh 5px;
    margin: 10vw;
    margin-top: 5vh;
    background: rgba(126, 101, 19, 0.833);
    opacity: 0.8;
    border-top: 5px solid rgba(255, 255, 255, 0.118);
}
.workshopPrice{
    position: relative;
    margin-top: 5vh;
    height: 100%;
    color: rgb(62, 60, 57);
}
.workshopPrice span{
    font-weight: bolder;
    color: rgb(191, 109, 74);
}

@media screen and (max-width: 456px) {
    .workshopHeading{
        font-size: 14vw;
    }
    
}
