.content {
  min-height: 70vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Guestcard {
  margin-top: -15vh;
  perspective: 150rem;
  height: 20rem;
  width: 20rem;
  position: relative;
}

.card__side {
  
  height: 25rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width: 20rem;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(black, 0.4);
}

.card__side--back {
  transform: rotateY(180deg);
}
.card__side--back img{
  
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;left: 0;
  object-fit: cover;
  opacity: 0.3;
  z-index: -1;
}
.Guestcard:hover .card__side--front {
  transform: rotateY(-180deg);
}

.Guestcard:hover .card__side--back {
  transform: rotateY(0deg);
}

.card__cont {
  border: 5px solid black;
  height: 24.5rem;
  display: flex;
  align-items:flex-end;
  justify-content: center;
}
.card__cont img{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;left: 0;
  object-fit: cover;
  z-index: -1;
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  color: white;
}

.card__cta p {
  margin-left: 1rem;
  font-size: 1rem;
}

.card__cta p > .space {
  margin-left: 2rem;
}

@import url('https://fonts.googleapis.com/css?family=Lora:700');
 
 .card__side {
	 box-shadow: 2px 2px 20px 0px rgba(50, 50, 50, 0.21);
	 border: 5px solid #d8b053;
}
 .card__side:before {
	 content: ' ';
	 display: block;
	 position: absolute;
	 height: 3px;
	 top: 2%;
	 left: 2%;
	 width: 10%;
	 background: #fff;
	 transition: width 1s;
}
 .card__side:hover::before {
	 width: 96%;
}
 .card__side:after {
	 content: ' ';
	 display: block;
	 position: absolute;
	 width: 3px;
	 top: 2%;
	 left: 2%;
	 height: 13%;
	 background: #fff;
	 transition: height 1s;
}
 .card__side:hover::after {
	 height: 96%;
}

 .card__side header:before {
	 content: ' ';
	 display: block;
	 position: absolute;
	 height: 3px;
	 bottom: 2%;
	 right: 2%;
	 width: 3%;
	 background: #fff;
	 transition: width 1s;
}
 .card__side header:hover::before {
	 width: 96%;
}
 .card__side header:after {
	 content: ' ';
	 display: block;
	 position: absolute;
	 width: 3px;
	 bottom: 2%;
	 right: 2%;
	 height: 3%;
	 background: #fff;
	 transition: height 1s;
}
 .card__side header:hover::after {
	 height: 96%;
}

.Guesttitle{
  width: 95%;
  margin: auto;
  border-radius: 50px;
  margin-top: 15vh;
  font-size: 10vh;
  font-weight: 900;
  color: #3b2c20;
  font-family: 'BasheqVintage', serif;
  margin-bottom: 2vh;
}
.guestContainer{
  padding: 3vh;
  background: linear-gradient(251.9deg, rgba(77, 53, 18, 0.65) -6.07%, rgba(27, 19, 4, 0.65) 47.04%, rgba(72, 58, 18, 0.65) 86.81%);
  border-top:5px solid rgba(255, 255, 255, 0.06);
  border-bottom:5px solid rgba(255, 255, 255, 0.06);
  width: 80%;
  margin: auto;
  border-radius: 50px;
  max-height: 100%;
  margin-bottom: 10vh;
  font-family: 'Old Standard TT', serif; /* Vintage-style font */
}
.guestContainer .card__cont .name{
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: wrap;
  background-color: rgba(0,0,0,0.6);
  padding: 0;margin: 0;width: 100%;
  font-family: 'Times New Roman', Times, serif;
}
.guestContainer .card__cont .name h3{
  color: rgb(222, 188, 132);
  font-weight: 700;
}
.guestContainer h1{
  color: rgb(255, 255, 255);
  font-weight: 700;
}
.guestCards{
  height: 100%;width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 469px){
  .Guestcard{
    width: 14rem;
    height: 17rem;
  }
  .card__side{
    width: 14rem;
    height: 17rem;
  }
  .card__cont {
    height: 16.5rem;
  }
  .card__cont .name h3{
    font-size: 1.2rem !important;
  }
  .card__cont .name h5{
    font-size: 1.1rem !important;
  }
  .card__cta p{
    font-size: 0.8rem;
  }
}