/* 🌟 General Styles */
.schedule {
  background: url("../img/hero-bg.png"); /* Light brown background */
  padding: 2rem 5%;
  max-height: 100%;
  min-height: 100vh;
}

.event-schedule {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Subtle vintage paper feel */
  border-radius: 10px;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.619);
}

/* 🌟 Title */
.title {
  color: #4a2c2a;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  margin-bottom: 1.5rem;
}

/* 🌟 Tabs */
.tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.tab {
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 20px;
  background: linear-gradient(to bottom, #d4b595, #a98467);
  color: #4a2c2a;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  font-size: 1rem;
}

.tab:hover {
  background: #b38a6c;
  transform: scale(1.05);
}

.tab.active {
  background: #4a2c2a;
  color: white;
}

/* 🌟 Progress Bar */
.progress-bar-schedule {
  width: 100%;
  height: 8px;
  background: #d4b595;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.progress-schedule {
  height: 100%;
  background: #3f2d3f;
  transition: width 0.5s ease-in-out;
}

/* 🌟 Schedule Details */
.schedule-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* 🌟 Event Cards */
.event-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-left: 5px solid #8b5e3b; /* Elegant left border */
}

.event-card:hover {
  transform: translateY(-3px);
  box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.15);
}

.event-info {
  text-align: left;
  color: #2b1e2b;
}

.event-title {
  color: #4a2c2a;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-family: 'Playfair Display', serif;
}

.event-location {
  color: #7a6f64;
  font-size: 1rem;
}

/* 🌟 Event Time */
.event-time {
  text-align: right;
  color: #2b1e2b;
}

.time {
  font-weight: bold;
  font-size: 1.1rem;
}

.duration {
  color: #7a6f64;
  font-size: 1rem;
}

/* 🌟 Responsive Design */
@media (max-width: 1024px) {
  .title {
    font-size: 2.2rem;
  }

  .tab {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .event-card {
    padding: 1.2rem;
  }

  .event-title {
    font-size: 1.2rem;
  }

  .event-location, .time, .duration {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .event-schedule {
    padding: 1rem;
  }

  .tabs {
    flex-wrap: wrap;
  }

  .tab {
    padding: 0.6rem 1rem;
    font-size: 0.85rem;
  }

  .event-card {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    padding: 1rem;
  }

  .event-time {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 1.8rem;
  }

  .tab {
    width: 80%;
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }

  .event-card {
    padding: 0.8rem;
  }

  .event-title {
    font-size: 1.1rem;
  }

  .event-location, .time, .duration {
    font-size: 0.85rem;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 1.6rem;
  }

  .tab {
    width: 90%;
    font-size: 0.75rem;
    padding: 0.5rem 0.7rem;
  }

  .event-title {
    font-size: 1rem;
  }

  .event-location, .time, .duration {
    font-size: 0.8rem;
  }
}
