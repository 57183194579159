@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Michroma", sans-serif;
  height: 100vh;
}

.container {
  background: linear-gradient(to bottom, #d4b595, #4a2c2a); /* Background color similar to the image */

  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #d4b595, #4a2c2a); /* Background color similar to the image */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #191654;
  z-index: 9999;
}

.loading-page svg {
  stroke: rgb(234, 210, 99);
  width: 100px;
  height: 100px;
  stroke-width: 1;
  stroke-dasharray: 500;
  animation: draw 8s ease;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.name-container {
  height: 30px;
  overflow: hidden;
}

.logo-name {
  color: #fff;
  font-size: 20px;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bolder;
}
