/* 🌟 Background Styling */
.contactus {
    background: linear-gradient(to bottom, #d4b595, #4a2c2a);
    min-height: 100vh;
    font-family: 'Playfair Display', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5vw;
}

/* 🌟 Header Styling */
.contactusTop {
    text-align: center;
    font-size: 5vw;
    font-weight: bold;
    color: #5D4037;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    padding-top: 10vh;
    line-height: 1.2;
}

/* 🌟 Contact Cards */
.contact-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2vw;
    margin-top: 3vw;
    width: 100%;
    padding: 0 5vw;
}

/* 🌟 Individual Contact Card */
.contact {
    background: linear-gradient(135deg, #f5e1c9, #f9f0e1);
    border: 2px solid #8D6E63;
    padding: 2vw;
    margin: 1vw;
    border-radius: 15px;
    width: 40%;
    text-align: center;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

/* 🌟 Hover Effect */
.contact:hover {
    transform: translateY(-5px);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #f3d6b5, #f7ebd8);
}

/* 🌟 Contact Info Styling */
.contact h2 {
    color: #5D4037;
    font-weight: bold;
    font-size: 1.8vw;
}
.contact a {
    color: #795548;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.2vw;
}

.contact p {
    font-size: 1.2vw;
    font-family: 'Times New Roman', Times, serif;
    color: #6D4C41;
}

/* 🌟 Phone Icon Styling */
.contact p::before {
    content: "📞 ";
    font-size: 1.4vw;
    color: #D84315;
}

/* 🌟 Responsive Design */
@media screen and (max-width: 970px) {
    .contact {
        width: 80%;
        font-size: 4vw;
    }
    .contact h2 {
        font-size: 5vw;
    }
    .contact a, .contact p {
        font-size: 4vw;
    }
}

@media screen and (max-width: 600px) {
    .contactusTop {
        font-size: 7vw;
    }
    .contact {
        width: 90%;
        padding: 5vw;
        font-size: 4vw;
    }
    .contact h2 {
        font-size: 5vw;
    }
    .contact a, .contact p {
        font-size: 4vw;
    }
    .contact p::before {
        font-size: 5vw;
    }
}

/* 🌟 Smallest Screen Adjustments */
@media screen and (max-width: 400px) {
    .contactusTop {
        font-size: 8vw;
    }
    .contact {
        width: 95%;
        padding: 6vw;
    }
    .contact h2 {
        font-size: 6vw;
    }
    .contact a, .contact p {
        font-size: 4.5vw;
    }
}
