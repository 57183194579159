@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,700&display=swap');


.techshowcase{
    height: 40%;
    width: 100%;
    padding:0 5vw 3vw 5vw;
    margin-bottom: 50px;
    position: relative;
}
.main{
    height: 100%;
    background: linear-gradient(251.9deg, rgba(76, 18, 77, 0.65) -6.07%, rgba(13, 4, 27, 0.65) 47.04%, rgba(70, 18, 72, 0.65) 86.81%);
    border-radius: 50px;
    border-top:4px solid rgba(255, 255, 255, 0.06);
}
.techshowcase .main .heading{
    color: rgb(209, 167, 16);
    font-size: 7.7vw;
    font-weight: 700;
    line-height: 11vw;
    font-family: 'BasheqVintage', serif;
}
.tech-gallery{
    margin-top: 2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
}

@media screen and (max-width: 1000px) {
    .tech-gallery{
        margin-top: 6vw;
    } 
}
@media screen and (max-width:360px){
    .techshowcase{
        margin-bottom: 20vw;
    }
    .techshowcase .main{
        height: 100%;
    }
    .techshowcase .heading{
        padding-top: 2vw;
        font-size: 10vw;
    }
    .tech-gallery{
        display: flex;
        flex-direction: column;
    }
}