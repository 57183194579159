#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  width: 100%; /* Make the slide take full width */
  height: auto; /* Allow the height to adjust automatically */
  max-width: 500px; /* Set a max width for consistency */
  padding: 10px;
}

.swiper-slide img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;  
  height: auto; 
  object-fit: contain; 
  border-radius: 10px; 
}

