@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");
* {
	-ms-overflow-style: none;
	box-sizing: border-box;
}
:root {
	--primary-color: #d800d2;
}
::-webkit-scrollbar {
	display: none;
}
.home-body {
	position: relative;
	background-color: #f5e6d3;
	overflow-x: hidden;
}
.body {
	position: relative;
	background: linear-gradient(to bottom, #d4b595, #4a2c2a); 

	overflow-x: hidden;
}

.container {
	background-color: #f5e6d3;
	width: 100%;
}
@media screen and (min-width: 600px) {
	.container {
		height: 100vh;
	}
}

#title {
	/* padding: auto; */
	font-family: "Montserrat", sans-serif;
}

#title h1 {
	font-size: 18vh;
	font-weight: 700;
	
}
#title h2 {
	font-size: 6vh;
	font-weight: 600;
}

.primary {
	background: linear-gradient(
		45deg,
		#d800d2,
		rgb(0, 179, 255),
		hsl(302, 100%, 42%),
		rgb(0, 179, 255),
		#d800d2,
		white
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media screen and (max-width: 500px) {
	#title h1 {
		font-size: 22vw;
		font-weight: 700;
	}
	#title h2 {
		font-size: 7vw;
		font-weight: 600;
	}
	/*     .container:first-of-type{
        height: 30vh;
    } */
	#home {
		height: 70vh;
	}
}

#home {
	position: relative;
	height: 100vh;
	width: 100%;
}
