@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.hero-container {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	object-fit: cover;
	text-align: center;
	background-image: url("../img/hero-bg.png");
	background-size: 100% 100%;
	background-position: center;
	padding-top: 10vh;
}
.hero-left{
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 50%;
	background-size: 100% 100%;
	background-image: url("../img/hero-Left.jpg");
}
.hero-right{
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 50%;
	background-size: 100% 100%;
	background-image: url("../img/hero-Right.jpg");
}

.hero-container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(151, 123, 123, 0.3); /* Lightens the image with a semi-transparent white overlay */
	z-index: 1;
}

.hero-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 0vh; /* Reduce gap between navbar and title */
	z-index: 2; /* Make sure the content appears above the overlay */
}

.hero-title {
	font-family: "Times New Roman", sans-serif;
	font-size: 12vh; 
	font-weight: 700;
	color: #4a1f1f;
}

.hero-subtitle {
	font-size: 5vh;
	font-weight: 600;
	color: #4a1f1f;
	font-family: 'BasheqVintage', serif;
}


.hero-logo {
	width: 250px;
	height: auto;
	margin-top: 15px;
}

.hero-bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	z-index: 2;
}

.hero-date {
	color: #301a1c;
	font-size: 3.5vh;
	font-weight: 600;
}

.countdown-timer {
	color: #4a1f1f; /* Make the color lighter for better contrast */
	font-size: 2.5vh;
	font-weight: bolder;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	position: relative;
	z-index: 2; 
	background-color: rgba(194, 189, 189, 0.82); 
	padding: 10px;
	border-radius: 1000px; 
}


@media screen and (max-width: 768px) {
	.hero-title {
		font-size: 9vw;
	}
	.hero-subtitle {
		font-size: 4.5vw;
	}
	.hero-logo {
		width: 140px;
	}
	.hero-date {
		font-size: 3vw;
	}
	.countdown-timer {
		font-size: 2vw;
		gap: 5px;
	}
}
