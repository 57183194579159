@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,700&display=swap');


.civilexpo{
    height: 60%;
    width: 80%;
    margin: auto;
    padding: 0 5vw 1vw 5vw;
    position: relative;
}

.civilexpo .main{
    max-height: 100%;
    width: 100%;
    margin: auto;
    background: linear-gradient(251.9deg, rgba(77, 53, 18, 0.65) -6.07%, rgba(27, 19, 4, 0.65) 47.04%, rgba(72, 58, 18, 0.65) 86.81%);    border-radius: 50px;
    border-top:4px solid rgba(255, 255, 255, 0.06);
    display: flex;
    flex-direction: column;

}

.civilexpo .main .heading{
    color: rgb(209, 167, 16);
    font-size: 7.7vw;
    font-weight: 700;
    line-height: 11vw;
    font-family: 'BasheqVintage', serif;
}
.civilexpo .main .eventCard{
    max-width: 60% !important;
    margin: auto;
    overflow: hidden;
}
.civilexpo .main .expo-gallery-row1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0;padding: 0 10px;
    margin-top: 50px;
}
.civilexpo .main .expo-gallery-row2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
@media screen and (max-width:900px){
    .civilexpo{
        height: 100%;
    }
}
@media screen and (max-width:525px){
    .civilexpo{
        height: 100%;
        width: 85%;
    }
    .civilexpo .main .heading{
        padding-top: 8vw;
        font-size: 12vw;
        margin-bottom: 16vw;
    }
}
@media screen and (max-width:470px){
    .civilexpo .main .card{
        width: 100%;
        margin: auto;
        margin: 20px auto;
    }
    .civilexpo .main .card .card-content{
        padding-left: 0;
    }
    .civilexpo .main .card h2{
        margin-left: 0;
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;
    }
    .civilexpo .main .heading{
        padding-top: 8vw;
        font-size: 10vw;
        margin-bottom: 0;
    }
}