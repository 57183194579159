/* 🌟 Vintage Theme - Button Container */
.events-buttonContainer {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

/* 🌟 Vintage Button Styling */
.events-button {
    display: flex;
    position: relative;
    width: 280px;
    height: 60px;
    text-decoration: none;
    background: linear-gradient(to bottom, #c4a484, #8b5e3b);
    border: 2px solid #6b4226;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    font-family: 'Times New Roman', serif;
    font-size: 20px;
    font-weight: bold;
    color: #3e2c20;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    filter: brightness(95%);
}

/* 📌 Vintage Paper Texture Effect */
.events-button::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("https://www.transparenttextures.com/patterns/aged-paper.png");
    mix-blend-mode: overlay;
    opacity: 0.5;
}

/* 📌 Button Hover Effect */
.events-button:hover {
    transform: translateY(-2px);
    filter: brightness(105%);
    background: linear-gradient(to bottom, #b88e63, #7a4d2a);
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.4);
}

/* 📌 Vintage Button Text */
.events-button__text {
    position: relative;
    z-index: 2;
    font-size: 24px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #2f1e14;
    transition: all 0.3s ease-in-out;
}

/* 📌 Button Press Effect */
.events-button:active {
    transform: translateY(3px);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

/* 🌟 Responsive for Tablets (max-width: 1024px) */
@media screen and (max-width: 1024px) {
    .events-button {
        width: 240px;
        height: 55px;
        font-size: 18px;
    }

    .events-button__text {
        font-size: 22px;
    }
}

/* 🌟 Responsive for Medium Devices (max-width: 768px) */
@media screen and (max-width: 768px) {
    .events-button {
        width: 220px;
        height: 50px;
        font-size: 16px;
    }

    .events-button__text {
        font-size: 20px;
        letter-spacing: 2px;
    }

    .events-buttonContainer {
        margin-top: 8vh;
    }
}

/* 🌟 Responsive for Small Mobile Devices (max-width: 480px) */
@media screen and (max-width: 480px) {
    .events-button {
        width: 80%;
        height: 45px;
        font-size: 14px;
    }

    .events-button__text {
        font-size: 18px;
        letter-spacing: 1.5px;
    }
}

/* 🌟 Responsive for Very Small Devices (max-width: 360px) */
@media screen and (max-width: 360px) {
    .events-button {
        width: 75%;
        height: 40px;
        font-size: 12px;
    }

    .events-button__text {
        font-size: 16px;
        letter-spacing: 1px;
    }
}
