.gallerycontain {
    margin-top: 20vh;
}

.imgGallery img {
    width: 28vw;
}

.imgGallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3vw;
    margin-bottom: 5vw;
}

.galimg {
    border-radius: 10px;
}
.group-title{
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.group-section{
    margin-bottom: 8rem;
}
.single-image{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh !important;
}
.single-image img{
    width: 50vw !important;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    margin-top: 24vh !important;
    margin-bottom: 10vh;
}
@media screen and (max-width: 900px) {
    .imgGallery {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3vw;
        margin: 5vw;
    }

    .imgGallery img {
        width: 40vw;
    }
    .single-image img{
        width: 70vw !important;
    }
}

@media screen and (max-width: 600px) {
    .imgGallery {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3vw;
        margin: 5vw;
    }

    .imgGallery img {
        width: 80vw;
    }
    .single-image img{
        width: 90vw !important;
    }
}

.single-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
}

.single-image img {
    max-width: 50vw; 
    height: auto;
    object-fit: contain; 
    border-radius: 10px; 
}


@media screen and (max-width: 900px) {
    .single-image img {
        max-width: 70vw;
    }
}

@media screen and (max-width: 600px) {
    .single-image img {
        max-width: 90vw;
    }
}
