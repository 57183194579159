
.body {
    background-color: #f4ecd8; /* Parchment-like color */
    background-image: url('../img/download.jpg'); /* Optional vintage texture */
    color: #4a3b2e; /* Dark brown text */
    font-family: 'Georgia', serif; /* Serif font for vintage look */
  }
  
  /* Heading */
  .accommodation-heading {
    background: rgba(221, 194, 160, 0.9); /* Vintage parchment color */
    color: #4a3b2e; /* Darker brown */
    margin-top: 15vh;
    border-radius: 30px;
    padding: 2vw;
    font-family: 'Georgia', serif;
  }
  
  .accommodation-heading h1 {
    font-weight: 700;
    font-size: 5vw;
  }
  
  .accommodation-heading span {
    color: #b87333; 
  }
  
  
  .accommodation-tabs .buttons {
    display: flex;
    justify-content: space-evenly;
  }
  
  .buttons .btn {
    margin: 1vw;
    background-color: #b87333; 
    border: none;
    color: white;
    font-weight: bold;
    font-family: 'Georgia', serif;
    font-size: 2.3vw;
    padding: 1vw 2vw;
    border-radius: 15px;
    transition: background-color 0.3s ease;
  }
  
  .buttons .btn:hover {
    background-color: #a0522d; 
    color: #f4ecd8; 
  }
  
  
  @keyframes display {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  
  .buttons .btn:nth-child(1) {
    opacity: 0;
    animation: display 0.5s ease-in-out forwards;
  }
  .buttons .btn:nth-child(2) {
    opacity: 0;
    animation: display 0.5s 0.3s ease-in-out forwards;
  }
  .buttons .btn:nth-child(3) {
    opacity: 0;
    animation: display 0.5s 0.6s ease-in-out forwards;
  }
  .buttons .btn:nth-child(4) {
    opacity: 0;
    animation: display 0.5s 0.9s ease-in-out forwards;
  }
  
  @media screen and (max-width: 1200px) {
    .accommodation-heading h1 {
      font-size: 8vw;
    }
    .accommodation-tabs .buttons {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 50% 50%;
    }
    .buttons .btn {
      font-size: 3.5vw;
      padding-bottom: 1vw;
    }
  }
  