/* 🌟 Base Styles */
.contain {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5%;
    min-height: 100vh;
    background: url("../img/hero-bg.png"); /* Light brown background */
    align-items: center;
    justify-content: center;
    text-align: left;
    gap: 20px; /* Adds spacing between elements */
    position: relative; /* Allows absolute positioning of shadow */
}

/* Top Shadow Effect */
.contain::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%; /* Controls shadow height */
    background: linear-gradient(to bottom, rgb(73, 41, 8), transparent); /* Dark brown fading */
    pointer-events: none; /* Ensures it doesn't block interactions */
}


/* Description Text */
.description {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #4a1f1f; /* Dark brown text */
    max-width: 600px;
    text-align: justify;
}

/* Logo Section */
.shilp-logo img{
    flex: 0 0 auto;
    margin-right: 40px;
    width: 350px;
    height: 350px;
}

/* 🌟 Stats Section */
.stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 14px;
    flex-wrap: wrap;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.521); /* Light opacity box */
    padding: 15px;
    border-radius: 10px;
    min-width: 140px;
    transition: transform 0.3s ease-in-out;
}

.stat-item:hover {
    transform: scale(1.05);
}

.stat-item h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #4a1f1f; /* Dark brown */
    margin: 5px 0;
}

.stat-item p {
    font-size: 1rem;
    color: #4a1f1f;
}

.icon {
    font-size: 2rem;
    color: #4a1f1f;
}

/* 🌟 Responsive Design */

/* 📌 Tablets (768px - 1024px) */
@media screen and (max-width: 1024px) {
    .contain {
        flex-direction: column;
        text-align: center;
        padding: 5%;
    }

    .shilp-logo img{
        margin-right: 0;
        margin-bottom: 20px;
        width: 300px;
        height: 300px;
    }

    .description {
        text-align: center;
        font-size: 1.1rem;
        line-height: 1.6rem;
        padding: 0 20px;
    }

    .stats {
        flex-direction: row;
        justify-content: center;
    }

    .stat-item {
        min-width: 130px;
        padding: 12px;
    }

    .stat-item h2 {
        font-size: 1.4rem;
    }

    .stat-item p {
        font-size: 0.9rem;
    }

    .icon {
        font-size: 1.8rem;
    }
}

/* 📌 Mobile Devices (500px - 767px) */
@media screen and (max-width: 768px) {
    .description {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0 10px;
    }

    .shilp-logo img{
        width: 280px;
        height: 280px;
    }

    .stats {
        flex-direction: column;
        align-items: center;
    }

    .stat-item {
        width: 90%;
        max-width: 250px;
        padding: 10px;
        margin-bottom: 15px;
    }

    .icon {
        font-size: 1.6rem;
    }

    .stat-item h2 {
        font-size: 1.3rem;
    }

    .stat-item p {
        font-size: 0.9rem;
    }
}

/* 📌 Small Mobile Devices (below 500px) */
@media screen and (max-width: 500px) {
    .contain{
        padding: 15% 10px;
    }
    .description {
        font-size: 0.9rem;
        line-height: 1.4rem;
        padding: 0 10px;
    }

    .shilp-logo img{
        width: 220px;
        height: 220px;
    }

    .stats {
        flex-direction: column;
        align-items: center;
    }

    .stat-item {
        width: 95%;
        max-width: 200px;
        padding: 8px;
    }

    .icon {
        font-size: 1.4rem;
    }

    .stat-item h2 {
        font-size: 1.2rem;
    }

    .stat-item p {
        font-size: 0.8rem;
    }
}
