.vintage-navbar {
    position: absolute;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.587); 
    padding-bottom: 0px;
    z-index: 9999999;
    font-family: 'Old Standard TT', serif; 
}

.vintage-navbar .navbar-brand img {
    height: 60px;
    width: 60px;
}

.vintage-navbar .nav-item {
    font-size: 1.2vw;
    font-weight: 600;
    color: #FFF8DC; 
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vintage-navbar .navbar-toggler {
    margin-right: 15px;
    border: 5px solid #d9d5b8;
}

.vintage-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.vintage-navbar .ProfilePic {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    justify-content: center;
}


.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #FFF8DC;
    font-size: 24px;
    cursor: pointer;
    display: none; 
}


.navbar .dropdown-menu {
    background-color: #8B7355; 
    border-radius: 10px;
    padding: 0;
    margin-top: 0.5rem; 
    top: 100%;
}

.dropdown .show {
    padding: 0px;
    border-radius: 10px;
    background-color: #8B7355;
}

.dropdown .show a, .dropdown-item {
    border: 1px solid white !important;
    color: #FFF8DC !important;
}

.dropdown-menu .dropdown-item {
    padding: 10px 20px;
    color: #FFF8DC;
    background: #8B7355;
    border: none;
}

.dropdown-menu .dropdown-item:hover {
    background: #654321; 
}


@media screen and (max-width: 1200px) {
    .vintage-navbar {
        background: transparent; 
    }

    .vintage-navbar .navbar-collapse {
        position: fixed;
        top: 0;
        right: -250px; 
        width: 250px;
        height: 100vh; 
        background: #8B7355; 
        transition: right 1.5s ease-in-out;
        padding-top: 60px; 
    }

    .vintage-navbar .navbar-collapse.show {
        position: fixed;
        height: 100vh; 
        z-index: 9999999;
        right: 0; 
    }

    .vintage-navbar .close-icon {
        display: block; 
    }

    .vintage-navbar .nav-item {
        font-size: 18px;
    }

    .vintage-navbar .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF8DC; 
    }

    .vintage-navbar .ProfilePic {
        display: flex;
        margin: auto;
    }

    .desktop-dropdown {
        display: none;
    }
}


@media screen and (min-width: 1201px) {
    .vintage-navbar .navbar-collapse {
        justify-content: flex-end; 
    }

    .vintage-navbar .navbar-brand {
        margin-right: auto; 
    }

  
    .desktop-dropdown {
        position: relative;
    }

    .desktop-dropdown .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        background: #8B7355;
        border: none;
    }

    .desktop-dropdown .dropdown-item {
        color: #FFF8DC;
        padding: 10px 20px;
        background: #8B7355;
    }

    .desktop-dropdown .dropdown-item:hover {
        background: #654321;
    }

    .mobile-profile {
        display: none;
    }
}
.event-workshop .dropdown-item{
    display: block;
}
.event-workshop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}