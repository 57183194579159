@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');

.about-ces {
    min-height: 100vh;  /* Changed from height to min-height for better responsiveness */
    width: 100%;
    background-color: #2e1633; /* Dark purple background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5vh 8vw;
    color: white;
}

/* 🌟 Title Styling */
.about-title {
    font-family: 'BasheqVintage', serif;
    letter-spacing: 0.3rem;
    font-size: 7vh;
    font-weight: 700;
    margin-bottom: 3vh;
}

/* 🌟 Logo Styling */
.logo img {
    width: 18vw;
    min-width: 150px;
    max-width: 220px;
    object-fit: contain;
    margin: 2vh 0;
}

/* 🌟 Description Text */
.about-description {
    font-family: 'Times New Roman', sans-serif;
    font-size: 2.5vh;
    font-weight: 300;
    line-height: 3.2vh;
    max-width: 75vw;
    margin-bottom: 3vh;
}

/* 🌟 Responsive for Tablets (max-width: 1024px) */
@media screen and (max-width: 1024px) {
    .about-title {
        font-size: 6vh;
    }

    .about-description {
        font-size: 2.2vh;
        line-height: 3vh;
        max-width: 80vw;
    }

    .logo img {
        width: 22vw;
    }
}

/* 🌟 Responsive for Medium Devices (max-width: 768px) */
@media screen and (max-width: 768px) {
    .about-title {
        font-size: 5vh;
    }

    .about-description {
        font-size: 2vh;
        line-height: 2.8vh;
        max-width: 85vw;
    }

    .logo img {
        width: 25vw;
        max-width: 180px;
    }
}

/* 🌟 Responsive for Small Mobile Devices (max-width: 480px) */
@media screen and (max-width: 480px) {
    .about-title {
        font-size: 4.5vh;
    }

    .about-description {
        font-size: 1.8vh;
        line-height: 2.5vh;
        max-width: 90vw;
    }

    .logo img {
        width: 30vw;
        max-width: 160px;
    }
}

/* 🌟 Responsive for Very Small Devices (max-width: 360px) */
@media screen and (max-width: 360px) {
    .about-title {
        font-size: 4vh;
    }

    .about-description {
        font-size: 1.6vh;
        line-height: 2.2vh;
        max-width: 95vw;
    }

    .logo img {
        width: 35vw;
        max-width: 140px;
    }
}
